.title{
    font-weight: 400;
    font-size: 32px;
}

.eventSect{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.eventSect p{
    font-weight: 500;
    font-size: 20px;
}

.timeLocation{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 5px;
}

.location{
    display: flex;
}

.eventText{
    font-weight: 400;
    font-size: 14px;
}

.viewSection{
    display: flex;
    align-items: center;
    margin-top: 10px;
    cursor: pointer;
}

.viewSection p{
    color: #0B91F1;
    font-weight: 400;
    font-size: 14px;
    text-decoration: underline;
    margin-right: 10px;
}

.tagSection{
    display: flex;
    align-items: center;
    margin-top: 10px;

    @media (max-width: 768px) {
        flex-direction: column;
        gap: 20px;
        margin-bottom: 30px;     
    }
}

.tag1{
    background-color: #FBF6EF;
    border-radius: 4px;
    padding-left: 10px;
    padding-right: 10px;
    color: #C59355;
    font-weight: 400;
    font-size: 10px;
    margin-right: 10px;
}

.tag2{
    background-color: #FEFBEB;
    border-radius: 4px;
    padding-left: 10px;
    padding-right: 10px;
    color: #F6D433;
    font-weight: 400;
    font-size: 10px;
    margin-right: 10px;
}

.tag3{
    background-color: #E7F4FE;
    border-radius: 4px;
    padding-left: 10px;
    padding-right: 10px;
    color: #0B91F1;
    font-weight: 400;
    font-size: 10px;
}