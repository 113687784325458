.sidebar {
    position: fixed;
    height: 100vh;
    white-space: nowrap;
    background-color: #0B91F1;
    border-inline-end: 1px solid #e5e6e6;
    overflow-y: auto;
    min-width: 18%;
    z-index: 100;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  
    @media (max-width: 1024px) {
      display: none;
      width: 70%;
      text-wrap: wrap;
    }
  }

  .mainContent {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-inline-start: 17%;
    height: 100vh;
  
    @media (max-width: 1024px) {
      padding-inline-start: 0;
    }
  }
  
  .children {
    height: 100%;
    padding-inline: 4rem;
    // padding-inline-end: 7.56rem;
    padding-block: 2rem;
    display: flex;
    flex-direction: column;
    background-color: #F6F6F6;
  
    @media (min-width: 2000px) {
      padding-inline-start: 15rem;
    }
  }

  .footerContainer{
    padding-left: 18%;
  }
  
  .logo {
    padding-inline-start: 1.25rem;
    padding-block-start: 1.5rem;
    width: 5.125rem;
    height: 1.94594rem;
  
    @media (max-width: 1024px) {
      padding-block-start: 0;
    }
  }
  
  .sidebarNav {
    padding-inline: 0.75rem;
    padding-block: 2.44rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 0.3rem;
    font-size: 1.2rem;
  
    & a,
    label {
      display: flex;
      align-items: center;
      gap: 0.62rem;
      background-color: transparent;
      border-radius: 0.625rem;
      padding-block: 0.87rem;
      padding-inline: 0.5rem;
      color: #ffffff;
  
      &:hover {
        background-color: #ce9938;
        //   color: #11643c;
      }
    }
    span {
      display: flex;
      align-items: center;
    }
  
    @media (max-width: 1024px) {
      padding-inline: 0;
    }
  }
  
  .sidebarNav a.activeLink {
    background-color: #ce9938;
    color: #ffffff;
    font-weight: 700;
  
    & svg path {
      fill: #11643c;
    }
  }
  
  .divider {
    background-color: #e5e6e6;
    border: none;
    height: 1px;
    width: 50%;
    margin-block: 0.5rem;
  }
  
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 3rem;
    font-size: 0.9rem;
    padding-block: 1rem;
    padding-inline-start: 3.75rem;
    padding-inline-end: 2.5rem;
    border-block-end: 1px solid #e5e6e6;
    background: #ffffff;
    position: sticky;
    top: 0;
    z-index: 20;
  
    @media (max-width: 768px) {
      padding-inline-start: 1rem;
      padding-inline-end: 1rem;
    }
  }
  
  .title {
    color: #011602;
    font-size: 1.5rem;
  
    @media (max-width: 1024px) {
      font-size: 1rem;
    }
  }
  
  .headerMenuGroup {


  
    & .menuBtn {
      display: none;
      border: none;
      @media (max-width: 1024px) {
        display: block;
      }
    }
  }
  
  .headerGroup {
    display: flex;
    align-items: center;
    gap: 1.25rem;
  
    & label {
      color: #67686d;
      font-size: 0.875rem;
      letter-spacing: 0.0875rem;
  
      @media (max-width: 1024px) {
        display: none;
      }
    }
  }
  
  .notifications {
    position: relative;
    border: none;
    background-color: transparent;
  
    &:hover {
      filter: none;
    }
  }
  
//   .notifyCount {
//     position: absolute;
//     top: -10px;
//     right: -10px;
//     width: 1.25rem;
//     height: 1.25rem;
//     border-radius: 50%;
//     background-color: #ea6664;
//     border: 1px solid #ffffff;
//     color: #ffffff;
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     text-align: center;
//     font-size: 0.625rem;
//     line-height: 0.75rem;
//   }
  
//   .account {
//     padding-inline-start: 0.25rem;
//     padding-inline-end: 0.62rem;
//     padding-block: 0.19rem;
//     border-radius: 0.99169rem;
//     background-color: #e5e6e6;
//     display: flex;
//     align-items: center;
//     gap: 0.37rem;
//     border: none;
//     outline: none;
  
//     & img {
//       width: 1.8125rem;
//       height: 1.8125rem;
//       border-radius: 50%;
//     }
//   }
  
//   .dropdown {
//     width: 25.1875rem;
//     border-radius: 1.25rem;
//     background-color: #fff;
//     border: 1px solid #e5e6e6;
//     box-shadow: 0px 4px 6px -4px rgba(16, 24, 40, 0.1);
//     padding-block: 1.5rem;
//     padding-inline: 1.5rem;
  
//     @media (max-width: 768px) {
//       width: 20rem;
//       padding-inline: 0.5rem;
//     }
// }


