.container{
    width: '100%';
    border: 1px solid #E6EAEE;
    padding: 10px;
}

h2{
    font-weight: 400;
    font-size: 20px;
}

.cardInputField{
    border: 1px #B9B9B9 solid;
    padding: 10px;
    border-radius: 8px;
    margin-bottom: 10px;
}

.cardInputField input{
    border: none;
    width: 100%;
}

.largeFlex{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
    width: 100%;
}

.images{
    display: flex;
}

.subStyling{
    display: flex;
    flex-direction: column;
    width: 50%;
}

.inputStyling{
    border: 1px #B9B9B9 solid;
    padding: 10px;
    border-radius: 8px;
    margin-bottom: 10px;
    width: 100%;
}

.inputStyling input{
    border: none;
}

.formattedStyling{
    display: flex;
    flex-direction: column;
    width: 50%;
    padding-right: 10px;
}

.payment{
    width: 100%;
    background-color: #0B91F1;
    border: 1px #0B91F1 solid;
    padding: 10px;
    border-radius: 4px;
    color: white;
    font-weight: 400;
    font-size: 16px;
}

@media (max-width: 768px){
    .largeFlex{
        flex-direction: column;
    }

    .subStyling{
        width: 100%;
    }

    .formattedStyling{
        padding-right: 0px;
        width: 100%;
    }
}