footer{
    background-color: #E6E6E6;
    width: '100%';
    margin-block-start: 5rem;
}

.biggerContainer{
    padding-left: 20px;
    padding-right: 20px;

}

.container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 50px;
}

.link{
    display: flex;
    align-items: center;
    margin-top: 10px;
}

.boldText{
    font-weight: 400;
    font-size: 32px;
}

.linkSpace{
    margin-right: 20px;
    margin-top: 10px;
}

.semiBoldText{
    font-weight: 400;
    font-size: 14px;
}

form{
    display: flex;
    align-items: center;
    margin-top: 10px;
}

form input{
    padding: 10px;
}

form button{
    margin-left: 10px;
    background-color: #0B91F1;
    padding: 10px;
    border: 1px #0B91F1 solid;
    border-radius: 4px;
    color: white;
}

.smallText{
    font-weight: 400;
    font-size: 10px;
}

.line{
    padding-bottom: 20px;
}

.line hr{
    color: black;
    border: 1px black solid;
    margin-top: 20px;
}

.termsandConds{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 40px;
}

.terms{
    display: flex;
}

.terms p{
    margin-right: 10px;
}

@media (max-width:768px){
    footer{
        width: 100%;
    }
    .container{
        flex-direction: column;
    }
    form{
        flex-direction: column;
    }

    form input{
        margin-bottom: 10px;
        width: 100%;
    }
    form button{
        width: 100%;
        margin: auto;
    }
    .newsletter p{
        text-align: center;
    }
    .biggerContainer{
        padding: 0;
    }
    .boldText{
        text-align: center;
    }
    .linkSpace{
        margin-right: 10px;
        width: 15%;
    }
    .lastMedia{
        width: 15%;
    }
    .terms{
        display: none;
    }

    .semiBoldText{
        text-align: center;
    }

    .copyright{
        margin: auto;
    }
    .link{
        justify-content: space-between;
    }

}