.headerSection{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.titleSection{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.profileSection{
    display: flex;
    align-items: center;
}
.title{
    font-weight: 500;
    font-size: 34px;
}
.profile{
    margin-left: 10px;
}
.nameSection{
    margin-left: 10px;
}
.arrow{
    margin-left: 10px;
}
.name{
    font-weight: 500;
    font-size: 14px;
}
.position{
    font-size: 12px;
    font-weight: 400;
}

.miniHeader{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
}

.miniHeader button{
    @media (max-width:768px) {
        display: none;
    }
}

.searchContainer{
    display: flex;
    align-items: center;
    border: 1px solid black;
    width: 40%;
    border-radius: 8px;
    padding-bottom: 15px;
    padding-top: 15px;
    padding-left: 15px;
    background-color: white;
    @media (max-width:768px) {
        width: 100%;
    }
}

.searchContainer input{
    border: none;
    width: 100%;
    margin-left: 5px;
}

.liveBtn{
    background-color: #0B91F1;
    padding: 10px;
    border: 1px #0B91F1 solid;
    border-radius: 4px;
    color: white;
}

.vicarSection{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 30px;
    @media (max-width:768px) {
        flex-direction: column;
    }
}


.vicarNameSection p{
    font-weight: 400;
    font-size: 20px;
}

.vicarNameSection h4{
    font-weight: 600;
    font-size: 48px;
}

.lead{
    margin-bottom: 20px;
    @media (max-width:768px) {
        text-align: center;
        margin-bottom: 10px;
    }
}

.vicarTitle{
    margin-bottom: 30px;
    @media (max-width:768px) {
        text-align: center;
        margin-bottom: 10px;
        font-size: 20px;
    }
}

.vicarImg{
    @media (max-width: 768px) {
        width: 90%;
    }
}

.bishopImg{
    @media (max-width: 768px) {
        width: 90%;
    }
}

.name{
    @media (max-width:768px) {
        text-align: center;
        margin-bottom: 10px;
    }
}