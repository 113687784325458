.titleSection{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.profileSection{
    display: flex;
    align-items: center;
}
.title{
    font-weight: 500;
    font-size: 34px;
}
.profile{
    margin-left: 10px;
}
.nameSection{
    margin-left: 10px;
}
.arrow{
    margin-left: 10px;
}
.name{
    font-weight: 500;
    font-size: 14px;
}
.position{
    font-size: 12px;
    font-weight: 400;
}
.miniHeader{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
}
.searchContainer{
    display: flex;
    align-items: center;
    border: 1px solid black;
    width: 40%;
    border-radius: 8px;
    padding-bottom: 15px;
    padding-top: 15px;
    padding-left: 15px;
    background-color: white;
}

.searchContainer input{
    border: none;
    width: 100%;
    margin-left: 5px;
}

.liveBtn{
    background-color: #0B91F1;
    padding: 10px;
    border: 1px #0B91F1 solid;
    border-radius: 4px;
    color: white;
}

.liveImage{
    width: 100%;
}

.addBtn p{
    color: white;
}

.btnDetails{
    display: flex;
    align-items: center;
}

.uploadBtn{
    background-color: white;
    padding: 10px;
    border: 1px #0B91F1 solid;
    border-radius: 4px;
    margin-left: 10px;
}

.uploadText{
    display: flex;
    align-items: center;
}

.iconStyle{
    margin-left: 5px;
}

.rowLayout{
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}

.imageBorder{
    border: 1px #E9E9E9 solid;
    padding: 20px;
    border-radius: 8px;
    margin-top: 20px;
}

.imageContainer{
    position: relative;
}

.centered{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.centered img{
    cursor: pointer;
}

.centered button{
    background-color: #0B91F1;
    border: 1px #0B91F1 solid;
    padding: 10px;
    border-radius: 4px;
    margin-left: auto;
    margin-right: auto;
    color: white;
    display: flex;
    margin-top: 20px;
}

.eventAddressTags{
    margin-top: 20px;
}

.eventText{
    font-weight: 400;
    font-size: 14px;
    // width: 40%;
}

.subTitle{
    font-weight: 400;
    font-size: 14px;
}

.eventContainer{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.eventContainer p{
    font-weight: 500;
    font-size: 20px;
}

.buttonIcon{
    display: flex;
    align-items: center;
}

.buttonIcon img{
    margin-left: 8px;
}

.timeLocation{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.location{
    display: flex;
    width: 30%;
}

.location p{
    margin-left: 8px;
}

.programContainer{
    width: 100%;
    border-radius: 1rem; /* rounded-lg equivalent */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.gridviewelement{
    margin-bottom: 20px !important;
    width: 200px;
}

.gridView{
    width: 100%;
    margin: auto;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-flow: row;
    gap: 4rem;
    margin-top: 1rem;
    margin-bottom: 1rem;

    // display: flex;
    // justify-content: space-between;
    // flex-wrap: wrap;
    // margin: auto;
    // margin-bottom: 1rem;
}

.programTitle{
    font-weight: 500;
    font-size: 20px;
    color: #010101;
}

.programSummary{
    font-weight: 400;
    font-size: 14px;
}

.programTagSection{
    // display: flex;
    // justify-content: space-between;
    margin-top: 10px;
}

.tagSection{
    display: flex;
}

.viewSection{
    display: flex;
    align-items: center;
    cursor: pointer;
}

.viewSection p{
    color: #0B91F1;
    font-weight: 400;
    font-size: 14px;
    text-decoration: underline;
    margin-right: 10px;
}

.gbg{
    background-color: #E6F8EE;
    border-radius: 4px;
    padding-left: 10px;
    padding-right: 10px;
    color: #55D28B;
    margin-right: 10px;
}

.ybg{
    background-color: #FEFBEB;
    border-radius: 4px;
    padding-left: 10px;
    padding-right: 10px;
    color: #F6D433;
    margin-right: 10px;
}

.bbg{
    background-color: #E7F4FE;
    border-radius: 4px;
    padding-left: 10px;
    padding-right: 10px;
    color: #0B91F1;
}
.eventLayoutRow{
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    align-items: center;
}
.boldText{
    font-weight: 400;
    font-size: 32px;
    margin-top: 30px;
}

.announcement{
    margin-top: 30px;
    position: relative;

}

.announcement img{
    width: 100%;
    filter: brightness(50%);
}

.announcementText{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.announcementText h4{
    color: white;
    font-weight: 700;
    font-size: 48px;
    text-align: center;
    margin-bottom: 20px;
}

.announcementText p{
    color: white;
    font-weight: 400;
    font-size: 20px;
    text-align: center;
}

.vicarSection{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
}


.nameSection p{
    font-weight: 400;
    font-size: 20px;
}

.nameSection h4{
    font-weight: 600;
    font-size: 48px;
}

.lead{
    margin-bottom: 20px;
}

.name{
    margin-bottom: 30px;
}


.dignitaries{
    display: flex;
    align-items: center;
    margin-top: 30px;
    cursor: pointer;
}

.dignitaries p{
    color: #0B91F1;
    text-decoration: underline;
    margin-right: 5px;
}

.programSection{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
}

.program{
    font-weight: 400;
    font-size: 32px;
}

.blue{
    font-weight: 500;
    font-size: 14px;
    color: #0B91F1;
}

.aboutUsSection{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 50px;
}

.aboutUsSection h4{
    font-weight: 600;
    font-size: 48px;
    margin-bottom: 30px;
}

.caption{
    font-weight: 400;
    font-size: 20px;
    margin-bottom: 20px;
}

.description{
    width: 70%;
    font-weight: 400;
    font-size: 20px;
    text-align: justify;
}

.centered h5{
    font-weight: 700;
    font-size: 48px;
    text-align: center;
    color: white;
    line-height: 62.4px;
}

.centered p{
    font-weight: 500;
    font-size: 20px;
    color: white;
    text-align: center;
    margin-top: 10px;
}

@media (max-width: 768px){
    .homeSection{
        width: 100%;
    }

    .eventLayoutRow{
        flex-direction: column;
        margin-top: 10px;
    }

    .boldText{
        text-align: center;
    }
    .miniHeader button{
        display: none;
    }
    .searchContainer{
        width: 100%;
    }
    .announcement{
        width: 100%;
    }
    .announcementText h4{
        font-size: 32px;
    }
    .announcementText p{
        font-size: 16px;
    }
    .vicarSection{
        flex-direction: column-reverse;
    }

    .vicarSection img{
        width: 92%;
    }
    
    .dignitaries{
        margin: auto;
        display: block;
    }

    .dignitaries img{
        display: none;
    }

    .dignitaries p{
        text-align: center;
        margin-right: 0px;
        margin-top: 5px;
    }

    .nameSection{
        margin-top: 20px;
    }

    .nameSection h4{
        font-size: 32px;
        text-align: center;
    }

    .nameSection p{
        text-align: center;
    }
    .lead{
        margin-bottom: 10px;
    }
    .name{
        margin-bottom: 10px;
    }
    // .programSection{
    //     flex-direction: column;
    //     align-items: center;
    //     margin-left: 0px;
    //     justify-content: center;
    // }
    .program{
        font-size: 20px;
    }
    .aboutUsSection{
        flex-direction: column-reverse;
    }
    .aboutUsSection img{
        width: 100%;
    }

    .aboutUsSection p{
        width: 100%;
    }
    .aboutUsText{
        margin-top: 20px;
    }
    .caption{
        display: none;
    }
    .centered p{
        display: none;
    }
    .centered h5{
        font-size: 20px;
        text-align: center;
        line-height: 30px;
    }
    .gridView{
        display: flex;
        flex-direction: column;
    }

    .programImage{
        width: 90%;
    }

    .aboutUsText h4{
        font-size: 40px;
    }
    .title{
        line-height: 3rem;
    }
    .tagSection{
        flex-direction: column;
        gap: 20px;
        width: auto;  
        margin-top: 20px;
    }

    .tagSection div{
        width: fit-content;
        margin: auto;
    }
    .memberBtn{
        width: 100%;
        margin-bottom: 20px;
    }
    .eventContainer{
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: auto;
        gap: 10px;
    }
    .eventContainer p{
        text-align: center;
    }
    .shareIcon{
        display: none;
    }
    .buttonIcon button{
        width: 100%;
    }
    .locationIcon{
        display: none;
    }
    .timeLocation{
        flex-direction: column;
        margin-top: 20px;
    }
    .location{
        width: 100%;
    }
    .location p{
        margin-left: 0px;
    }
    .subTitle{
        text-align: center;
    }
}
