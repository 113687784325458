@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

html,
body {
	height: 100%;
	scroll-behavior: smooth;
	font-family: 'Inter', sans-serif !important;
}

:root {
	min-height: 100vh;
	font-size: 14px;
	font-family: 'Inter', sans-serif !important;
}

body {
  margin: 0;
  font-family: 'Inter', sans-serif;
	font-weight: 400;
  font-style: normal;
	font-optical-sizing: auto;
	font-variation-settings:
    "wdth" 100,
    "YTLC" 500;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 62.5%;
	line-height: 1.875rem;
  background-color: #FFFFFF;
	--color-primary: #0B91F1;
	--color-light-green: #D5E4D6;
	--color-secondary: #E7F5E8;
	--color-white: #ffffff;
	--color-black: #000000;

	--font-bigger: 1.5rem;
	scroll-behavior: smooth;
}

a,
a:link,
a:visited {
	text-decoration: none;
	color: inherit;
}

*,
*::before,
*::after {
	box-sizing: border-box;
	padding: 0;
	margin: 0;
}

input, select, textarea {
	outline: none !important;
	font-family: "Inter", sans-serif;
}

button {
	cursor: pointer;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
	margin: 0;
}

h1 {
	font-size: 50px;
	font-weight: 800;
}
h2 {
	font-size: 36px;
  font-weight: 800;
}
h3 {
	font-size: 30px;
  font-weight: 800;
}
h4 {
	font-size: 24px;
  font-weight: 800;
}
h5 {
font-size: 18px;
  font-weight: 400;
}


button {
  cursor: pointer;
	font-family: 'Inter', sans-serif;
}

// reusable styles
.space-between {
	display: flex;
  align-items: center;
  justify-content: space-between;
	text-wrap: nowrap;
}

.flex-start {
	display: flex;
  align-items: flex-start;
}

.fields {
	display: flex;
	flex-direction: column;
	gap: 1.143rem;
}

.small-gap {
	display: flex;
  align-items: center;
	gap: 0.938rem;
}

.btn-group {
	display: flex;
	text-align: center;
	justify-content: center;
	gap: 0.571rem;
}

.underline {
	display: block;
	background-color: var(--color-secondary);
	height: 6px;
	width: 60%;
}

.text-center {
	display: block;
	text-align: center;
}

.ant-modal-content {
	padding: 0 !important;
}
.ant-modal-header {
	padding: 20px 24px !important;
}
.ant-modal-body {
  padding-inline: 40px !important;
  padding-block: 20px !important;
}
.ant-modal-footer {
	padding-inline: 40px !important;
  padding-block-end: 24px !important;
	text-align: center !important;
}
.ant-modal-close {
  position: absolute !important;
  right: 20px !important;
	top: 20px !important;
}