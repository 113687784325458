.titleSection{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.profileSection{
    display: flex;
    align-items: center;
}
.title{
    font-weight: 500;
    font-size: 34px;
}
.profile{
    margin-left: 10px;
}
.nameSection{
    margin-left: 10px;
}
.arrow{
    margin-left: 10px;
}
.name{
    font-weight: 500;
    font-size: 14px;
}
.position{
    font-size: 12px;
    font-weight: 400;
}
.miniHeader{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
}
.searchContainer{
    display: flex;
    align-items: center;
    border: 1px solid black;
    width: 40%;
    border-radius: 8px;
    padding-bottom: 15px;
    padding-top: 15px;
    padding-left: 15px;
    background-color: white;
}

.searchContainer input{
    border: none;
    width: 100%;
    margin-left: 5px;
}

.liveBtn{
    background-color: #0B91F1;
    padding: 10px;
    border: 1px #0B91F1 solid;
    border-radius: 4px;
    color: white;
}

.liveImage{
    width: 100%;
}

.addBtn p{
    color: white;
}

.btnDetails{
    display: flex;
    align-items: center;
}

.uploadBtn{
    background-color: white;
    padding: 10px;
    border: 1px #0B91F1 solid;
    border-radius: 4px;
    margin-left: 10px;
}

.uploadText{
    display: flex;
    align-items: center;
}

.iconStyle{
    margin-left: 5px;
}

.rowLayout{
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}

.imageBorder{
    border: 1px #E9E9E9 solid;
    padding: 20px;
    border-radius: 8px;
    margin-top: 20px;
}

.imageContainer{
    position: relative;
}

.centered{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.centered img{
    cursor: pointer;
}

.eventAddressTags{
    margin-top: 20px;
}

.eventText{
    font-weight: 400;
    font-size: 14px;
}

.eventContainer{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.eventContainer p{
    font-weight: 500;
    font-size: 20px;
}

.buttonIcon{
    display: flex;
    align-items: center;
}

.buttonIcon img{
    margin-left: 8px;
}

.timeLocation{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.location{
    display: flex;
}

.location p{
    margin-left: 8px;
}

.tagSection{
    display: flex;
    align-items: center;
    margin-top: 10px;
}

.tagSection p{
    font-weight: 400;
    font-size: 10px;
}

.gbg{
    background-color: #E6F8EE;
    border-radius: 4px;
    padding-left: 10px;
    padding-right: 10px;
    color: #55D28B;
    margin-right: 10px;
}

.ybg{
    background-color: #FEFBEB;
    border-radius: 4px;
    padding-left: 10px;
    padding-right: 10px;
    color: #F6D433;
    margin-right: 10px;
}

.bbg{
    background-color: #E7F4FE;
    border-radius: 4px;
    padding-left: 10px;
    padding-right: 10px;
    color: #0B91F1;
}
.eventLayoutRow{
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    align-items: center;
}
.boldText{
    font-weight: 400;
    font-size: 32px;
    margin-top: 30px;
}

@media (max-width: 768px){
    .eventLayoutRow{
        flex-direction: column;
    }
    .boldText{
        text-align: center;
    }
    .miniHeader button{
        display: none;
    }
    .searchContainer{
        width: 100%;
    }
    .title{
        line-height: 3rem;
    }   
}
