.titleSection{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.title{
    font-weight: 500;
    font-size: 34px;
    @media (max-width:768px) {
        line-height: 3rem;   
    }
}


.miniHeader{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 30px;
}


.searchContainer{
    display: flex;
    align-items: center;
    border: 1px solid black;
    width: 40%;
    border-radius: 8px;
    padding-bottom: 15px;
    padding-top: 15px;
    padding-left: 15px;
    background-color: white;
    @media (max-width:768px) {
        width: 100%;
    }
}

.searchContainer input{
    border: none;
    width: 100%;
    margin-left: 5px;
}

.liveBtn{
    background-color: #0B91F1;
    padding: 10px;
    border: 1px #0B91F1 solid;
    border-radius: 4px;
    color: white;
    @media (max-width:768px) {
        display: none;
    }
}