@import '../../../utils/mixins';

.container{
    width: '100%';
}

.styling{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    @include respond-to-tablet{
        flex-direction: column;
    }
}

.smallFlex{
    display: flex;
}

.smallFlex p{
    margin-right: 3px;
}

.smallFlex img{
    cursor: pointer;
}