.headerSection{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.profileSection{
    display: flex;
    align-items: center;
}
.title{
    font-weight: 500;
    font-size: 34px;
}
.profile{
    margin-left: 10px;
}
.nameSection{
    margin-left: 10px;
}
.arrow{
    margin-left: 10px;
}
.name{
    font-weight: 500;
    font-size: 14px;
}
.position{
    font-size: 12px;
    font-weight: 400;
}
.miniHeader{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
}
.searchContainer{
    display: flex;
    align-items: center;
    border: 1px solid black;
    width: 40%;
    border-radius: 8px;
    padding-bottom: 15px;
    padding-top: 15px;
    padding-left: 15px;
    background-color: white;
}

.searchContainer input{
    border: none;
    width: 100%;
    margin-left: 5px;
}

.liveBtn{
    background-color: #0B91F1;
    padding: 10px;
    border: 1px #0B91F1 solid;
    border-radius: 4px;
    color: white;
}

.contType{
    font-weight: 400;
    font-size: 32px;
    margin-top: 30px;
}
.collapseLabel {
    display: flex;
    align-items: center;
    font-size: 20px;
    font-weight: 500;
}

.iconStyle {
    font-size: 24px;
    background-color: #1890ff;
    margin-right: 10px;
    border-radius: 12px;
    padding: 8px;
    width: 44px;
    height: 44px;
}

.icon{
    margin-left: auto;
    margin-right: auto;
    display: block;
}

.flutterwave{
    margin-right: 10px;
}

@media (max-width: 768px){
    .iconStyle{
        font-size: 20px;
    }

    .flutterwave{
        display: none;
    }

    .contType{
        line-height: 3rem;
    }
    .searchContainer{
        display: none;
    }
    .contType{
        font-size: 20px;
    }
}

