.headerContainer{
    display: flex;
    justify-content: space-between;
}

.miniHeader{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 30px;
    margin-bottom: 20px;
}

.searchContainer{
    display: flex;
    align-items: center;
    border: 1px solid black;
    width: 40%;
    border-radius: 8px;
    padding-bottom: 15px;
    padding-top: 15px;
    padding-left: 15px;
    background-color: white;
    @media (max-width:768px) {
        width: 100%;   
    }
}

.searchContainer input{
    border: none;
    width: 100%;
    margin-left: 5px;
}

.liveBtn{
    background-color: #0B91F1;
    padding: 10px;
    border: 1px #0B91F1 solid;
    border-radius: 4px;
    color: white;
    @media (max-width:768px) {
        display: none;
    }
}

.bodyContainer{
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media (max-width:768px) {
        flex-direction: column;
    }
}

.bodyContainer img{
    @media (max-width:768px) {
        width: 100%;
        margin-bottom: 20px;
    }
}



 .formContainer p{
    font-weight: 400;
    font-size: 14px;
    color: #343434;
    @media (max-width:768px) {
     text-align: center;   
    }
}

h5{
    font-weight: 600;
    font-size: 48px;
    margin-top: 8px;
    margin-bottom: 10px;
    @media (max-width:768px) {
     font-size: 30px;
     text-align: center;   
    }
}

.inputStyling{
    border: 1px #B9B9B9 solid;
    padding: 10px;
    border-radius: 8px;
    margin-bottom: 10px;
    width: 100%;
    background-color: white;
    align-items: center;
}

.inputContainer p{
    font-weight: 400;
    font-size: 16px;
}

.inputStyling input{
    border: none;
    width: 100%;
    margin: auto;
}

.terms{
    display: flex;
    margin-bottom: 10px;
}

.terms p{
    margin-left: 8px;
}

.formContainer button{
    background-color: #0B91F1;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 30px;
    padding-right: 30px;
    border: 1px #0B91F1 solid;
    border-radius: 4px;
    color: white;
    @media (max-width:768px) {
     width: 100%;   
    }
}