.galleryContainer{
    width: 100%;
    // border-radius: 1rem; /* rounded-lg equivalent */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.gridView{
    width: 100%;
    margin: auto;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-flow: row;
    gap: 4rem;
    margin-top: 1rem;
    margin-bottom: 1rem;

    // display: flex;
    // justify-content: space-between;
    // flex-wrap: wrap;
    // margin: auto;
    // margin-bottom: 1rem;
    @media (max-width:768px) {
        display: flex;
        flex-direction: column;
    }
}

.galleryContainer p{
    font-size: 20px;
    font-weight: 500;
    color: #010101;
}

.galleryContainer span{
    font-size: 14px;
    font-weight: 400;
    color: #010101;
}

.galleryContainer img{
    @media (max-width:768px) {
        width: 100%;
    }
}

.bulletin{
    width: 100%;
    cursor: pointer;
}