@import '../../utils/mixins';

.eventTitleContainer{
    padding: 20px;
}

.bigImage{
    width: 100%;
}

.titleAndShare{
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}

.locationAndTime{
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    margin-bottom: 8px;
    align-items: center;
}

.location{
    display: flex;
}

.tagSection{
    display: flex;
    margin-bottom: 10px;
    @media (max-width:768px) {
        flex-direction: column;
        gap: 20px;
        width: auto;  
        margin-top: 20px;
        margin-bottom: 20px;
    }
}

.tagSection div{
    @media (max-width:768px) {
        width: fit-content;
        margin: auto;
    }
}

.gbg{
    background-color: #E6F8EE;
    border-radius: 4px;
    padding-left: 10px;
    padding-right: 10px;
    color: #55D28B;
    margin-right: 10px;
}

.ybg{
    background-color: #FEFBEB;
    border-radius: 4px;
    padding-left: 10px;
    padding-right: 10px;
    color: #F6D433;
    margin-right: 10px;
}

.bbg{
    background-color: #E7F4FE;
    border-radius: 4px;
    padding-left: 10px;
    padding-right: 10px;
    color: #0B91F1;
}

.register{
    background-color: #0B91F1;
    padding: 10px;
    border: 1px #0B91F1 solid;
    border-radius: 4px;
    color: white;
    @media (max-width:768px) {
        width: 100%;
    }
}

.guestContainer{
    margin-top: 20px;
    @include respond-to-tablet{
      margin-top: 40px;
    }
}

.guests{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
    @include respond-to-tablet{
        flex-direction: column;
        justify-content: center;
        margin: auto;
        gap: 30px;
    }

}

.guestName{
    font-weight: 600;
    font-size: 20px;
}

.guestPosition{
    font-weight: 400;
    font-size: 16px;
}

.guestDesc{
    font-weight: 400;
    font-size: 16px;
    margin-top: 8px;
    text-align: justify;
}

.guestItem{
    width: 30%;
    @include respond-to-tablet{
        justify-content: center;
        width: 100%;
        margin: auto;
        text-align: center;
        margin-bottom: 20px;
    }
}
.guestTitle{
    font-weight: 500;
    font-size: 20px;
    @include respond-to-tablet{
        text-align: center;
        margin-bottom: 10px;
    }
}
.media{
    display: flex;
    gap: 20px;
    margin-top: 10px;
    @include respond-to-tablet{
        justify-content: center;
    }
}

.container{
    display: flex;
    flex-direction: column;
    // padding-top: 1rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    padding-bottom: 20px;
}

.formContainer{
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-bottom: 20px;
}

// .inputStyling{
//     // border: 1px #B9B9B9 solid;
//     padding: 10px;
//     border-radius: 8px;
//     margin-bottom: 10px;
//     width: 100%;
//     background-color: white;
//     // align-items: center;
// }

.inputContainer input{
    border: none;
    width: 100%;
    margin: auto;
}

.inputContainer{
    width: 100%;
    border: 1px solid black;
    border-radius: 8px;
    padding: 10px;
    @media (max-width:768px) {
        width: 100%;   
    }
}

.btn{
    background-color: #0B91F1;
    padding: 14px;
    border: 1px solid #0B91F1;
    border-radius: 4px;
    font-weight: 400;
    font-size: 16px;
    color: white;
}