.titleSection{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.title{
    font-weight: 500;
    font-size: 34px;
}
.miniHeader{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 30px;
}
.searchContainer{
    display: flex;
    align-items: center;
    border: 1px solid black;
    width: 40%;
    border-radius: 8px;
    padding-bottom: 15px;
    padding-top: 15px;
    padding-left: 15px;
    background-color: white;
}

.searchContainer input{
    border: none;
    width: 100%;
    margin-left: 5px;
}

.liveBtn{
    background-color: #0B91F1;
    padding: 10px;
    border: 1px #0B91F1 solid;
    border-radius: 4px;
    color: white;
}
.programText{
    font-size: 32px;
    margin-bottom: 15px;
    font-weight: 400;
    margin-top: 30px;
}

.programContainer{
    width: 100%;
    border-radius: 1rem; /* rounded-lg equivalent */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.gridviewelement{
    margin-bottom: 20px !important;
    width: 200px;
}

.gridView{
    width: 100%;
    margin: auto;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-auto-flow: row;
    gap: 4rem;
    margin-top: 1rem;
    margin-bottom: 1rem;

    // display: flex;
    // justify-content: space-between;
    // flex-wrap: wrap;
    // margin: auto;
    // margin-bottom: 1rem;
}



.programTitle{
    font-weight: 500;
    font-size: 20px;
    color: #010101;
}

.programSummary{
    font-weight: 400;
    font-size: 14px;
}

.viewSection{
    display: flex;
    align-items: center;
    cursor: pointer;
}

.viewSection p{
    color: #0B91F1;
    font-weight: 400;
    font-size: 14px;
    text-decoration: underline;
    margin-right: 10px;
}

.tagSection{
    // display: flex;
    // justify-content: space-between;
    margin-top: 10px;
    margin-bottom: 30px;
}

@media(max-width:768px){
    .gridView{
        display: flex;
        flex-direction: column;
    }
    .titleSection img{
        display: none;
    }
    .liveBtn{
        display: none;
    }
    .searchContainer{
        width: 100%;
    }
    .title{
        line-height: 2.5rem;
    }
    .programImage{
        width: 90%;
    }
}