.formContainer{
    display: flex;
    flex-direction: column;
    width: 100%;
}

.inputFlex{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 20px;
    @media (max-width:768px) {
        flex-direction: column;
    }
}

p{
    font-weight: 400;
    font-size: 20px;
}

.inputStyling{
    border: 1px #B9B9B9 solid;
    padding: 10px;
    border-radius: 8px;
    margin-bottom: 10px;
    width: 100%;
    background-color: white;
    align-items: center;
}

.inputStyling input{
    border: none;
    width: 100%;
    margin: auto;
}

.inputContainer{
    width: 50%;
    @media (max-width:768px) {
        width: 100%;   
    }
}

.rightInputContainer{
    width: 45%;
    // padding-left: 20px;
    @media (max-width:768px) {
        width: 100%;   
    }
}

textarea{
    width: 100%;
    height: 100px;
    padding-top: 10px;
    padding-left: 10px;
    border-radius: 8px;
    margin-top: 10px;
}

.btn{
    width: 100%;
    background-color: #0B91F1;
    border: 1px #0B91F1 solid;
    padding: 10px;
    border-radius: 4px;
    color: white;
    font-weight: 400;
    font-size: 16px;
    margin-top: 10px;
}

h4{
    font-weight: 400;
    font-size: 32px;
    margin-bottom: 20px;
    margin-top: 20px;
}